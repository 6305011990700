  .tracking-col{
      float: left;
      margin-left: 15px;
      width: 125px;
  }

  .popover {
    max-width: 1000px !important;
    min-width: 200px;
    width:auto !important;
  }
  .popover-header{
    background-color: #ffffff !important;
    border-bottom: 0px solid #ebebeb !important;
    font-family: "C-Scania Sans Semi Cond-18", Arial !important;
    font-size: 16px !important;
    font-weight: bold;
  }
  .popover-body{
    font-family: "C-Scania Sans Semi Cond-10", Arial !important;
    font-size: 14px;
    font-weight: normal;
  }
  .tooltip-tracktrace-header{
    line-height: 18px !important;
    font-family: "C-Scania Sans Semi Cond-18", Arial !important;
    font-size: 13px !important;
    color: rgb(148, 153, 157) !important;
    line-height: 18px !important;   
    font-weight: bold; 
  }

.tooltip-tracktrace-margin{
  padding-top: 5px;
}

.tooltip-tracktrace-content{
  line-height: 21px;
  color: rgb(36, 36, 36);
  font-size: 14px;
  font-family: "C-Scania Sans Semi Cond-10", Arial !important;
}

.tooltip-tracktrace-copy{
  color: rgb(74, 139, 164);
  font-size: 14px;
  font-family: "C-Scania Sans Semi Cond-18", Arial !important;
  font-weight: bold;
}

.tooltip-status-main-div{
  width: 300px !important; 
}
.tracktrace-iconsize {
  height: 25px;
  width: 25px;
  margin-right: 5px; 
  float: left;
}
.float-left-div{
  float: left;
}
.tooltip-Width{
  max-width: 300px;
}
.companycard-img{
  width: 24px;
  height: 24px;
}
li{
  padding-top: 5px;
}